#bibliograph {
  min-height: 90vh;
  .my-pic {
    padding: 1rem 0;
    grid-area: selfie;

    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 100%;
      height: $portfolio-icon-size;
      width: $portfolio-icon-size;
      object-fit: contain;
      transition: transform 100ms ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
