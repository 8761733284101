@keyframes absCenteredZoomOut {
  0% {
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0;
  }
  85% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes discoText {
  0% {
    color: rgb(random(255), random(255), random(255));
  }
  85% {
    color: rgb(random(255), random(255), random(255));
  }
  100% {
    color: rgb(random(255), random(255), random(255));
  }
}

@keyframes shake {
  0% {
    transform: rotateZ(0deg) scale(1);
  }
  25% {
    transform: rotateZ(5deg) scale(1.2);
  }
  50% {
    transform: rotateZ(0deg) scale(1.2);
  }
  75% {
    transform: rotateZ(-5deg) scale(1.2);
  }
  100% {
    transform: rotateZ(0deg) scale(1);
  }
}

@keyframes shiftBackgroundRight {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

@keyframes flashHintCell {
  0% {
    background-color: $shuduCell-bgColor-changable;
  }
  50% {
    background-color: yellow;
    color: cyan;
  }
  100% {
    background-color: $shuduCell-bgColor-changable;
  }
}
