.section_heading {
  font-size: $section-heading-font-size;
  font-weight: $section-heading-font-weight;
  padding-bottom: 0.6rem;
  color: transparent;

  background-image: linear-gradient(
    to right bottom,
    $palette-blue-20-1,
    $vein-fur-blue
  );

  background-clip: text;
  &:hover {
    animation: shake 0.3s ease;
  }
  @media screen and (max-width: 990px) {
    font-size: $section-heading-font-size-md;
    font-weight: $section-heading-font-weight-md;
  }
  @media screen and (max-width: 600px) {
    font-size: $section-heading-font-size-sm;
    font-weight: $section-heading-font-weight-sm;
  }
}

.resumePage_subskillsHeading {
  font-family: "Ribeye-Regular";
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  padding: 1rem 0;
}

.resumeSkillText {
  font-family: "Chicle-Regular";
  letter-spacing: 0.2rem;
  background-image: linear-gradient(
    to bottom,
    $nav-left-bg-clr,
    $nav-left-bg-clr
  );
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

// @font-face {
//   font-family: "piedraregular";
//   src: url("../../src/assets/fonts/piedra-regular-webfont.woff2")
//       format("woff2"),
//     url("../../src/assets/fonts/piedra-regular-webfont.woff") format("woff"),
//     url("../../src/assets/fonts/Piedra-Regular.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Chicle-Regular";
//   src: url("../../src/assets/fonts/Chicle/Chicle-Regular.eot"); /* IE9 Compat Modes */
//   src: url("../../src/assets/fonts/Chicle/Chicle-Regular.eot?#iefix")
//       format("embedded-opentype"),
//     /* IE6-IE8 */ url("../../src/assets/fonts/Chicle/Chicle-Regular.otf")
//       format("opentype"),
//     /* Open Type Font */ url("../../src/assets/fonts/Chicle/Chicle-Regular.svg")
//       format("svg"),
//     /* Legacy iOS */ url("../../src/assets/fonts/Chicle/Chicle-Regular.ttf")
//       format("truetype"),
//     /* Safari, Android, iOS */
//       url("../../src/assets/fonts/Chicle/Chicle-Regular.woff") format("woff"),
//     /* Modern Browsers */
//       url("../../src/assets/fonts/Chicle/Chicle-Regular.woff2") format("woff2"); /* Modern Browsers */
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Ribeye-Regular";
//   src: url("../../src/assets/fonts/Ribeye/Ribeye-Regular.eot"); /* IE9 Compat Modes */
//   src: url("../../src/assets/fonts/Ribeye/Ribeye-Regular.eot?#iefix")
//       format("embedded-opentype"),
//     /* IE6-IE8 */ url("../../src/assets/fonts/Ribeye/Ribeye-Regular.otf")
//       format("opentype"),
//     /* Open Type Font */ url("../../src/assets/fonts/Ribeye/Ribeye-Regular.svg")
//       format("svg"),
//     /* Legacy iOS */ url("../../src/assets/fonts/Ribeye/Ribeye-Regular.ttf")
//       format("truetype"),
//     /* Safari, Android, iOS */
//       url("../../src/assets/fonts/Ribeye/Ribeye-Regular.woff") format("woff"),
//     /* Modern Browsers */
//       url("../../src/assets/fonts/Ribeye/Ribeye-Regular.woff2") format("woff2"); /* Modern Browsers */
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "ConcertOne-Regular";
//   src: url("../../src/assets/fonts/ConcertOne/ConcertOne-Regular.eot"); /* IE9 Compat Modes */
//   src: url("../../src/assets/fonts/ConcertOne/ConcertOne-Regular.eot?#iefix")
//       format("embedded-opentype"),
//     /* IE6-IE8 */
//       url("../../src/assets/fonts/ConcertOne/ConcertOne-Regular.otf")
//       format("opentype"),
//     /* Open Type Font */
//       url("../../src/assets/fonts/ConcertOne/ConcertOne-Regular.svg")
//       format("svg"),
//     /* Legacy iOS */
//       url("../../src/assets/fonts/ConcertOne/ConcertOne-Regular.ttf")
//       format("truetype"),
//     /* Safari, Android, iOS */
//       url("../../src/assets/fonts/ConcertOne/ConcertOne-Regular.woff")
//       format("woff"),
//     /* Modern Browsers */
//       url("../../src/assets/fonts/ConcertOne/ConcertOne-Regular.woff2")
//       format("woff2"); /* Modern Browsers */
//   font-weight: normal;
//   font-style: normal;
// }
