.project-card {
  display: flex;
  margin: 2rem 0rem;
  border: 2px solid #ccc;
  box-shadow: 0.1rem 0.1rem #eee;
  padding-bottom: 2rem;
  &-imgcontainer {
    align-self: center;
    max-height: 75%;
    max-width: 20%;

    margin: 0rem 1rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &-texts {
    .project-card-title {
      font-size: 2rem;
      a {
        text-decoration: none;
      }
    }
    .project-card-time {
      text-decoration: underline;
      font-size: 1.1rem;
    }
    .project-card-description {
      padding-left: 1rem;
      li {
        padding: 0.1rem 0;
      }
    }
  }
}
