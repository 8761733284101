#contact-form-sec {
  border-top: $vein-eye-black dotted 2px;
  display: flex;
  flex-direction: column;
  .mail-icon {
    display: flex;
    justify-content: center;
    font-size: 2rem;
  }

  .form-area {
    flex-grow: 1;
    position: relative;
    .contact-form {
      padding: 0 0.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;

      .input-field {
        min-height: 2rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        &-label {
          padding-left: 0.3rem;
        }
        &-input {
          width: 80%;
          height: 80%;
          padding-left: 1rem;
          align-self: flex-end;
          box-shadow: 1px 1px #eee;
          border-radius: 25px;
        }
      }

      .msg-input {
        min-height: 8rem;
        flex-grow: 1;
        textarea {
          min-height: 7rem;
          border-radius: 8px;
        }
      }
      .contact-btns {
        justify-self: flex-end;
        display: flex;
        justify-content: space-around;

        button {
          width: 35%;
          height: 3rem;
          @include onHoverScale(1.2);
        }
      }
    }
    .form-spinner {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .backdrop {
    backdrop-filter: blur(10px);
    border-radius: 5px;
    background-color: rgba($color: #000000, $alpha: 0.6);
    pointer-events: none;
  }
}
