.timeline-container {
  .timeline-line {
    position: relative;
  }
  .timeline-line::after {
    content: "";
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    right: 16px;
  }

  .timeline-time {
    position: relative;
    font-weight: 300;
    font-size: 2rem;
    padding-right: 36px;
    &-content {
      overflow: hidden;
      color: #ff9f55;
    }
  }
  .timeline-time::after {
    content: "";
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    right: 16px;
  }
  .timeline-time::before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: 6px;
    background-color: white;
    border: 4px solid #ff9f55;
    border-radius: 50%;
  }

  @media screen and (max-width: 990px) {
    .timeline-item {
      margin-bottom: 2rem;
    }
  }
}
